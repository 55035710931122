import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { BaseRequestService } from 'app/_services/base.service';
import { ModalService } from 'app/_services/modal.service';
import { ShiftComponent } from './shift/shift.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/_services/common.services';

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user'
})
export class UserComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    shiftslist: any=[];
    ro_code=''
    dulist: any=[];
    nozzlelist: any;
    userdetails: any;
    selectedDu:any=[];
    selectedNozzle:any=[];
    nozzleref: any;
    mapdata ={
        'ServerAdmin':'Server Admin',
        'SuperAdmin':'Super Admin',
        'ClientAdmin':'Client Admin',
        'RoDealer':'RO Dealer',
        'Viewer':'Viewer',
        'RoManager':'RO Manager',
        'CustomerAttendant':'Customer Attendant'
    }
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _matDialog: MatDialog,
        public modalService:ModalService,
        private api:BaseRequestService,
        private _userService: UserService,
        private _activatedRoute:ActivatedRoute,
        private _fuseConfirmationService:FuseConfirmationService,
        public cs:CommonService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                this.user = JSON.parse(localStorage.getItem('user'))
                this.user.role = this.user.roles[0]
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

    }

   

    openpopup(){
        const dialogRef = this._matDialog.open(ShiftComponent);

        dialogRef.afterClosed()
                 .subscribe((result) => {
                 });
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void
    {
        // Return if user is not available
        if ( !this.user )
        {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
    }

    manual(){
        window.open("https://digitalpetro.atlassian.net/wiki/spaces/DI1/pages/8421504/Digitalpetro+IOML", '_blank');
    }
    
    /**
     * Sign out
     */
    signOut(): void
    {   
        const confirmation = this._fuseConfirmationService.open({
            title  : `Sign Out`,
            message: `Dear ${this.user.name}, are you sure you want to sign out from the application?`,
            actions: {
                confirm: {
                    label: 'OK'
                }
            }
        });
        confirmation.afterClosed().subscribe((result) => {
          if(result === 'confirmed'){
            var snapshot = this._activatedRoute.snapshot;
            const params = { ...snapshot.queryParams };
            delete params.queryParamMap
        localStorage.clear()
        sessionStorage.clear()
        this.api.doRequest('/api/logout', 'get', null).subscribe( data =>{
            this._router.navigate(['/sign-out']);

        })}
    })

    }


    resetpassword(){
        window.open(`${this.cs.baseurl}/resetpassword?uid=${this.user._id}`, '_blank');
    }
}
